import { reactive, ref, computed, toRaw, watch } from "vue";
import { useStore } from 'vuex'
import moment from 'moment'
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { fetchEntityList } from "@/views/Checking/spotChecking/spotCheckingVonder/checkingPreparation/API";
import { getPayoutRoundByYear, getDealerSalesStatus, getSalesList } from "@/API/payment";
const useEntitySiPayout = () => {
  // column
  const column = reactive([
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
      slots: { customRender: "round" },
      class: 'column-no-wrap',
      width: 120
    },
    {
      title: "Payout Amount w/o VAT",
      dataIndex: "amountWOVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 180
    },
    {
      title: "Payout Amount w VAT",
      dataIndex: "amountWVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
      width: 180
    },
    {
      title: "BU No.",
      dataIndex: "buNum",
      align: "right",
      width: 120
    },
    {
      title: "",
      dataIndex: "",
      width: 50
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 120
    },
    {
      title: "Document Preparation",
      slots: { customRender: "documentOperation" },
      width: 180
    },
    {
      title: "Operation",
      slots: { customRender: "operation" },
      width: 120
    },
  ]);
  // 查询条件
  const query = reactive({
    payoutYear: moment().format("YYYY"),
    payoutRound: undefined,
    status: undefined,
    entity: "",
    type: "DEALER",
  });
  // 分页
  const pagination = reactive({
    total: 0,
    pageNumber: 1,
    pageSize: 10,
  });
  // status 列表
  const statusRes = ref();
  const statusList = computed(() => {
    return statusRes.value;
  });
  const getSatusList = () => {
    getDealerSalesStatus().then((res) => {
      statusRes.value = res;
    });
  };
  const store = useStore()
  // entity 列表
  const entityRes = ref();
  const entityList = computed(() => {
    return store.state.user.entityList.map((item: any) => item.entityNameEn)
  });
  // const getEntityList = () => {
  //   return new Promise((resolve, reject) => {
  //     fetchEntityList().then((res: any) => {
  //       entityRes.value = res;
  //       resolve(res?.data?.data[0]);
  //     });
  //   });
  // };
  // 查round列表
  const roundRes = ref();
  const roundList = computed(() => {
    return roundRes.value;
  });
  const getRound = () => {
    query.payoutRound = undefined
    getPayoutRoundByYear(query.payoutYear, query.entity).then((res) => {
      roundRes.value = res;
    });
  };
  // table Data
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value?.content;
  });
  const getTableData = () => {
    const _query: {[key: string]: any} = {
      ...toRaw(query),
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize
    };
    _query.status =  _query.status === undefined ? '0' : _query.status
    _query.payoutRound =  _query.payoutRound === undefined ? '' : _query.payoutRound
    _query.year = _query.payoutYear
    getSalesList(_query).then((res) => {
      pagination.total = res.totalElements
      dataRes.value = res;
    });
  };
  const pageChange = (page: number, size: number) => {
    pagination.pageSize = size
    pagination.pageNumber = page
    getTableData()
  }
  const sizeChange = (page: number, size: number) => {
    pagination.pageSize =size
    pagination.pageNumber = page
    getTableData()
  }
  // 监听payout round变化
  watch(() => query.payoutYear, (val) => {
    if (typeof val !== 'string') {
      query.payoutYear = moment(val).format("YYYY")
      getRound()
    }
  })
  return {
    query,
    column,
    data,
    getTableData,
    pagination,
    entityList,
    roundList,
    getRound,
    statusList,
    getSatusList,
    pageChange,
    sizeChange
  };
};
export default useEntitySiPayout;
